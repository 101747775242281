import { z } from 'zod';
// import { parsePhoneNumberFromString } from "libphonenumber-js";

interface GeoLocation {
  latitude: number;
  longitude: number;
}

export interface UserInterface {
  first_name: string;
  last_name: string;
  username: string;
  email: string;
  business_name: string;
  is_verified: boolean;
  nationality_country_id: number;
  residency_country_id: number;
  residency_city: string;
  residency_postal_code: string;
  phone_number: string | null;
  whatsapp_number: string | null;
  telegram_number: string | null;
  wechat_number: string | null;
  marital_status: string | null;
  gender: string | null;
  bio: string | null;
  geo_location: GeoLocation;
  is_onboarded: boolean;
  profile_picture_url:string;
  latitude?: number;
  longitude?: number;
}

// -- profile setup
export type OnboardingType = {
  username: string;
  nationality: number;
  longitude: number;
  latitude: number;
};
export type UserOnboardingType = {
  username: string;
  nationality: number;
  longitude: number;
  latitude: number;
  first_name: string;
  last_name: string;
  email: string;
  whatsapp_number: string | number;
  marital_status: string;
  gender: string;
  bio: string;
  profile_picture: File | null;
};
export type ProfileSetupType = {
  username: string;
  nationality: number;
};
export const ProfileSetupDefault: ProfileSetupType = {
  username: '',
  nationality: -1,
};

export const ProfileSetupSchema = z.object({
  username: z.string().min(3, 'Enter a nickname'),
  nationality: z.number().min(1, 'Select your Nationality'),
});

// Account setup
export type AccountSetupType = {
  first_name: string;
  last_name: string;
  username: string;
  business_name?: string;
  profession: string;
  hobby: string;
  email: string;
  nationality_country_id: number;
  whatsapp_number: string | number;
  marital_status: string;
  religion: string;
  gender: string;
  bio: string;
};


export const AccountSetupSchema = z.object({
  first_name: z.string().min(3, 'Enter a first name'),
  last_name: z.string().min(3, 'Enter a last name'),
  username: z.string().min(3, 'Enter a nickname'),
  business_name: z.string().min(3, 'Enter busienss'),
  email: z.string().email(),
  profession: z.string().min(3, 'Enter a profession'),
  hobby: z.string().min(3, 'Enter a hobby'),
  bio: z.string().min(3, 'Enter a bio'),
  nationality_country_id: z.number().min(1, 'Select your Nationality'),
  whatsapp_number: z.string(),
  marital_status: z.string().min(1, "Please select a marital status"),
  religion: z.string().min(1, "Please select a religion"),
  gender: z.string().min(1, "Please select a gender"),
});

// .regex(/^[a-zA-Z]+$/, 'Enter a valid name'),
