import { FormEvent, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import ButtonComponent from '../../components/ButtonComponent';
import InputField from '../../components/InputField';
import useFormInput from '../../custom-hooks/useFormInput';
import AuthLayout from '../../layouts/AuthLayout';
import { LuArrowLeft, LuGlobe, LuUser2, LuAsterisk } from 'react-icons/lu';
import { z } from 'zod';
import {
  OnboardingType,
  ProfileSetupDefault,
  ProfileSetupSchema,
  ProfileSetupType,
} from '../../app-schemas/profile.schema';
import content_mgt from '../../api-services/contents/contents_data.services';
import profile_api from '../../api-services/profile/profile.services';
import { RES_MSSG } from '../../api-services/ConstMssg';
import { SITELINK } from '../../app-routes/Links';
import { ErrorMessage } from '../../components/ErrorMessage';
import { getUser, storeUser } from '../../app-utils';

// data type
type Location = {
  latitude: number;
  longitude: number;
};

// custom js fn to handle type Guard
function isLocation(value: any): value is Location {
  return value && typeof value.latitude === 'number' && typeof value.longitude === 'number';
}

// main-component-ui
const ProfileSetup = () => {
  document.body.classList.remove('overflow-hidden');
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [countries, setCountries] = useState<any>([]);
  const [locationAccessMessage, setLocationAccess] = useState<string>('OPEN');
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [formError, setFormError] = useState<any>();

  useEffect(() => {
    getCountries();
  }, []);

  // custom hook to get && validate users input
  const [formData, handleChange, errors, setValidationErrors, handleCustomChange] = useFormInput(
    ProfileSetupDefault,
    ProfileSetupSchema,
  );

  // fn to get countries
  // store res in react state
  const getCountries = async () => {
    const res = await content_mgt.Countries();
    const options = res.data.data.map((i: any) => ({ value: i.country_id, label: i.nationality }));

    setCountries(options);
  };

  // code to get user current location
  ///profile/geolocateCommunities
  // to be modified to custom hooks if needed in multiple places
  const getUserLocation = () => {
    return new Promise((resolve, reject) => {
      setLocationAccess('OPEN');

      if ('permissions' in navigator) {
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
          if (permissionStatus.state === 'denied') {
            setErrorMsg(
              'Location access is blocked. To use this feature, please enable location services in your browser settings.',
            );
            setLocationAccess('DENIED');
          }
        });
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords);
          },
          (error) => {
            setErrorMsg(`Error getting location`);
            reject(null);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          },
        );
      } else {
        const error = 'Geolocation is not supported by this device.';
        setErrorMsg(error);
        reject(new Error(error));
      }
    });
  };

  // fn to handleOnboarding
  const handleOnboarding = async (e: FormEvent) => {
    e.preventDefault();
    const { success, data, error } = ProfileSetupSchema.safeParse(formData);

    if (success) {
      const position = await getUserLocation();
      if (isLocation(position)) {
        const coordinates: Location = position;

        let payload = {
          username: data.username,
          nationality: data.nationality,
          longitude: coordinates.longitude,
          latitude: coordinates.latitude,
        };
     
        OnbaordUserWithLocation(payload);
      } else {
        setErrorMsg('Location is required to proceed.');
      }
    } else {
      handleValidationErrors(error);
    }
  };

  // main method to handle err && res
  const OnbaordUserWithLocation = async (data: OnboardingType) => {
    setLoading(true);

    try {
      const res = await profile_api.UpdateProfile(data);

      if (res?.data?.message === RES_MSSG.PROFILE_UPDATED) {
        let me = getUser();
        me.username = data.username;
        storeUser(me);

        navigate(SITELINK.HOME);
      } else {
        setErrorMsg(res?.response?.data?.message);
      }
    } catch (error) {
      setErrorMsg('An error occured!');
    } finally {
      setLoading(false);
    }
  };

  const handleValidationErrors = (error: z.ZodError<ProfileSetupType>) => {
    const newErrors: Partial<Record<keyof ProfileSetupType, string>> = {};
    error.errors.forEach((err) => {
      newErrors[err.path[0] as keyof ProfileSetupType] = err.message;
    });
    setValidationErrors(newErrors);
    setFormError(newErrors);
  };

  return (
    <AuthLayout title="Catapot | Profile Setup">
      {/* hero-text area */}
      <div className="flex flex-col items-start mt-12">
        <Link
          to="/"
          className="flex gap-1 text-start text-sm font-medium mb-4 mt-6 font-medium text-custom-600"
        >
          <LuArrowLeft className="text-2xl" />
          <span className="font-semibold mt-[2px]">Go back</span>
        </Link>

        <h4 className="text-[1rem] font-[500] text-[#181e35] mt-6">Let's get your profile setup</h4>
        <p className="text-xs font-[500] text-gray-500 relative top-[-6px]">
          Complete all required fields (
          <span className="text-xl text-red-600 top-2 relative">*</span>)
        </p>
      </div>

      <div className="text-red-600 text-xs mt-3 col-span-full text-center">{errorMsg}</div>

      {/* complete form area */}
      <form onSubmit={handleOnboarding} className="grid grid-cols-2 gap-3 mt-5">
        <InputField
          className="col-span-full text-sm rounded-lg"
          type="text"
          id="username"
          placeholder="Nickname"
          label="Nickname"
          value={formData.username.replace(/[^\w\s]|_/g, '').replace(/\s+/g, '')}
          onChange={handleChange}
          required
          icon={LuUser2}
          error={errors?.username}
        />

        <div className="relative w-full col-span-full">
          <label className="flex text-xs text-gray-700 font-[600]">
            <span className="text-red-600 top-[2px] relative">
              <LuAsterisk />
            </span>
            <span>Nationality</span>
          </label>

          <div className="relative w-full col-span-full">
            <span className="absolute p-[5px] rounded-[50px] top-[8px] left-[7px] text-xl text-[#98A1B0]">
              <LuGlobe />
            </span>
            <select
              className={`focus:outline-none focus:shadow-outline border border-input border-gray-300 rounded-md pl-9 px-4 py-3 w-full text-md text-gray-700 focus:shadow-lg focus:border-[#58595c] ${
                !!formError?.nationality && 'border-red-600'
              }`}
              onChange={(e) => handleCustomChange('nationality', Number(e.target.value))}
            >
              <option value="" disabled selected>
                Select your Nationality
              </option>
              {countries.map((item: any) => (
                <option key={item.value} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {!!formError?.nationality && <ErrorMessage message={formError?.nationality} />}
          </div>
        </div>

        <div className="col-span-full">
          {locationAccessMessage === 'OPEN' && (
            <div className="text-sm mt-2 col-span-full flex bg-gray-100 border rounded-lg py-6 px-6">
              {/* <LuBadgeInfo className="text-[60px] text-gray-500 font-semibold" /> */}
              <p>Click continue grant location access to determine your country of residency.</p>
            </div>
          )}

          {locationAccessMessage === 'DENIED' && (
            <div className="text-[12px] col-span-full flex bg-red-50 border rounded-md py-1 px-3">
              <div>
                <p>
                  Location access is blocked. To use this feature, please enable location services
                  in your browser settings.
                </p>
                <ol className="ol">
                  <li>1. Open Chrome settings.</li>
                  <li>2. Go to "Site settings".</li>
                  <li>3. Tap "Location".</li>
                  <li>4. Find and select your website.</li>
                  <li>5. Allow location access.</li>
                </ol>
                <button className="bg-gray-500 text-green-100 py-1 px-4 mt-3 mb-1 border rounded shadow">
                  Retry
                </button>
              </div>
            </div>
          )}

          <div className="text-[10px] text-end col-span-full text-gray-500 font-medium hidden">
            <Link to="/faq/why-do-i-need-location"> Why do i need to allow my location ?</Link>
          </div>
        </div>

        {/* action btn */}
        <ButtonComponent
          className="w-[200px] mt-2 text-sm  py-3 rounded-lg text-white bg-custom-600"
          type="submit"
          disabled={loading}
          loading={loading}
        >
          Continue
        </ButtonComponent>
      </form>
    </AuthLayout>
  );
};

export default ProfileSetup;
