import { useEffect, useState, useMemo } from 'react';
import { LuArrowRight, LuCamera } from 'react-icons/lu';
import { Community } from '../../app-schemas/community/community.interface';
import { UserInterface } from '../../app-schemas/profile.schema';
import AppLayout from '../../layouts/AppLayout';
import { getUser, getFirstLetters } from '../../app-utils';
import CommunityCard from './home-components/CommunityCard';
import WelcomeMessage from './home-components/WelcomeMessage';
import { Link } from 'react-router-dom';
import { SITELINK } from '../../app-routes/Links';
import { useRecommendedCommunities } from '../../api-services/community/useRecommendedCommunities';
import community_api from '../../api-services/community/community.services';
import PostCard from './home-components/PostCard';
import { Loader } from '../../assets/custom-icons';
import { useInView } from 'react-intersection-observer';
import { useInfiniteQuery } from '@tanstack/react-query';
import { FeedsQueryKeys } from '../../api-services/feeds/feeds.keys';
import { fetchFeeds } from '../../api-services/feeds/useFetchNewFeeds';
import { PostInterface } from '../../app-schemas/posts/post.schema';
import profile_api from '../../api-services/profile/profile.services';

// data type
type Location = {
  latitude: number;
  longitude: number;
};

// custom js fn to handle type Guard
function isLocation(value: any): value is Location {
  return value && typeof value.latitude === 'number' && typeof value.longitude === 'number';
}

const Home = () => {
  const { ref, inView } = useInView();
  const [isWelcomeMssg, setIsWelcomeMssg] = useState(true);
  const user: UserInterface = getUser(); // getUser from localstorage
  const recommendedCommunities = useRecommendedCommunities();

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } = useInfiniteQuery({
    queryKey: FeedsQueryKeys.all,
    queryFn: fetchFeeds,
    initialPageParam: 1,
    getNextPageParam: (lastPage) =>
      lastPage.currentPage < lastPage.totalPages ? lastPage.currentPage + 1 : undefined,
  });

  const content = useMemo(
    () =>
      data?.pages.flatMap((page) =>
        page.data.map((post: PostInterface, index: number) => (
          <PostCard
            key={post.post_id}
            index={post.post_id}
            post={post}
            innerRef={page.data.length - 2 === index + 1 ? ref : undefined}
          />
        )),
      ),
    [data, ref],
  );

  useEffect(() => {
    if (inView && hasNextPage) fetchNextPage();
  }, [inView, hasNextPage, fetchNextPage]);

  useEffect(() => {
    handleLocationCommunities();
    // eslint-disable-next-line
  }, []);
  
  // code to get user current location
  ///profile/geolocateCommunities
  // to be modified to custom hooks if needed in multiple places
  const getUserLocation = async () => {
    return new Promise((resolve, reject) => {
      if ('permissions' in navigator) {
        navigator.permissions.query({ name: 'geolocation' }).then((permissionStatus) => {
          if (permissionStatus.state === 'denied') {
            console.log('DENIED');
          }
        });
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve(position.coords);
          },
          (error) => {
            console.log(`Error getting location`);
            reject(null);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
          },
        );
      } else {
        const error = 'Geolocation is not supported by this device.';
        console.log(error);
        reject(new Error(error));
      }
    });
  };

  // fn to handleOnboarding
  const handleLocationCommunities = async () => {

    const position = await getUserLocation();

    console.log(position)

    if (isLocation(position)) {
      const coordinates: Location = position;

      let payload = {
        longitude: coordinates.longitude,
        latitude: coordinates.latitude,
      };

      setLocation(payload);
    }
  };

  // main method to handle err && res
  const setLocation = async (data: any) => {
    try {
      const res = await profile_api.UpdateLocation(data);
      console.log(res);
    } catch (error) {
      console.log(error);
    } finally {
      console.log(false);
    }
  };

  const handleJoinCommunity = async (id: number) => {
    try {
      const res = await community_api.JoinCommunity(id);
      if (res?.data?.status) {
        recommendedCommunities.refetch();
      }
    } catch (error) {
      console.error('Error joining community:', error);
    }
  };

  const renderProfilePicture = () =>
    user.profile_picture_url ? (
      <img
        alt="profile"
        src={user.profile_picture_url}
        className="object-cover rounded-full w-[40px] h-[40px] bg-gray-200 shadow-sm"
      />
    ) : (
      <div className="block w-[40px] h-[40px] bg-[#223f64] rounded-full border border-green-100 flex items-center justify-center">
        <h3 className="text-white text-[1.4rem] uppercase font-[600]">
          {getFirstLetters(user.first_name ?? user.username)}
        </h3>
      </div>
    );

  if (recommendedCommunities.isLoading || isLoading) {
    return (
      <AppLayout title="Catapot - Home">
        <div className="flex py-5 justify-center items-center h-[90vh]">
          <img src={Loader} alt="loader" className="h-[20px] w-[20px]" />
        </div>
      </AppLayout>
    );
  }


  return (
    <AppLayout title="Catapot - Home">
      {/* make a new post section */}
      <section className="relative bg-white border-t border-gray-300 shadow-xl px-2 py-2 flex gap-1 item-center justify-center">
        <Link to={`${SITELINK.MY_PROFILE}/${user?.username}`} className="w-[50px] block">
          {renderProfilePicture()}
        </Link>

        <Link
          to={SITELINK.COMPOSER}
          className="text-sm p-2 px-4 w-full border-gray-300 text-start text-gray-600 font-[400] border rounded-[50px]"
        >
          Have something to say?
        </Link>

        <Link
          to={SITELINK.COMPOSER}
          className="text-[1.4rem] text-gray-500 absolute right-[18px] top-[12px] bg-white rounded-[50px] bg-white p-1 rounded-full"
        >
          <LuCamera />
        </Link>
      </section>

      {/* ! new account Welcome message */}
      {user?.residency_city && isWelcomeMssg && (
        <WelcomeMessage user={user} onClose={() => setIsWelcomeMssg(false)} />
      )}

      {/*  recommended community list/action btn  */}
      {!!recommendedCommunities?.data?.length && (
        <section className="bg-white mt-2 border shadow-sm">
          <div className="font-[500] flex justify-between text-gray-700 pt-3 px-3">
            <span className="text-sm font-semibold mt-1">Find communities</span>
          </div>

          <div className="search-item-filter w-full flex overflow-x-scroll scroll-smooth scroll-style mt-2 pb-5">
            {recommendedCommunities?.data?.map((community: Community, index: number) => (
              <CommunityCard
                key={index}
                community={community}
                onClick={handleJoinCommunity}
                title="join"
                className="max-w-[40vw] ml-3"
              />
            ))}

            <Link
              to={SITELINK.MORE_COMMUNITIES}
              className="bg-[#fff] flex custom-min-width justify-center items-center p-6 px-2"
            >
              <div className="flex flex-col items-center gap-2 text-center">
                <button className="justify-center flex flex-col item-center mt-1 mx-5">
                  <LuArrowRight className="text-[3rem] bg-custom-600 text-white font-semibold py-2 rounded-full shadow-xl" />
                </button>

                <p className="justify-center text-xs font-semibold mt-2 text-gray-600">Find More</p>
              </div>
            </Link>
          </div>
        </section>
      )}

      {/* community feeds section */}
      <section className="mb-20">
        <div className="text-sm">{content}</div>

        {isFetchingNextPage && (
          <div className="flex py-5 justify-center">
            <img src={Loader} alt="loader" className="h-[20px] w-[20px]" />
          </div>
        )}

        {!hasNextPage && <div className="text-xs flex justify-center pt-5">No content</div>}
      </section>
    </AppLayout>
  );
};

export default Home;
