import { LuAlignRight, LuBell, LuSearch, LuX } from 'react-icons/lu';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SITELINK } from '../../app-routes/Links';
import { getFirstLetters, getUser } from '../../app-utils';
import { UserInterface } from '../../app-schemas/profile.schema';
import { Verify } from '../../assets/custom-icons';
import {
  RiAccountPinCircleLine,
  RiBookmarkLine,
  RiGift2Line,
  RiHeart3Line,
  RiLogoutCircleLine,
  RiSecurePaymentFill,
  RiSettings5Line,
  RiSignalTowerFill,
} from 'react-icons/ri';
import { useFetchUnreadNotifications } from '../../api-services/notifications/useFetchUnreadNotifications';

// main-component-ui
const TopNav = () => {
  const navigate = useNavigate();

  // gloabl state localstorage
  const user: UserInterface = getUser();
  const [scrollDirection, setScrollDirection] = useState<string | null>(null);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [isOpen, setIsOpen] = useState(false);

  const unread_notification = useFetchUnreadNotifications();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const direction = prevScrollPos > currentScrollPos ? 'up' : 'down';
      if (currentScrollPos > 60) {
        setScrollDirection(direction);
      } else {
        setScrollDirection(null);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  return (
    <>
      {/* main top area */}
      <div
        className={`shadow-sm flex justify-between w-full px-4 py-3 bg-custom-600 border-b z-50 fixed top-0 transition-all duration-500 ${
          scrollDirection === null
            ? 'translate-y-0'
            : scrollDirection === 'up'
            ? 'translate-y-0'
            : '-translate-y-full'
        }`}
      >
        <div className="flex gap-2">
          <span className="playwrite-fr-trad text-lg text-white font-[900]">Catapot</span>
        </div>
        <div className="text-gray-200 text-[1.5rem] pt-1 flex gap-5">
          <Link to={SITELINK.APP_SEARCH}>
            <LuSearch />
          </Link>

          <Link to={SITELINK.NOTIFICATIONS} className="relative">
            <LuBell />
            {unread_notification?.data?.length > 0 && (
              <span className="notification-pointer"></span>
            )}
          </Link>

          <button onClick={toggleSidebar}>
            {isOpen ? (
              <LuX className="text-gray-200" />
            ) : (
              <LuAlignRight className="text-gray-200" />
            )}
          </button>
        </div>
      </div>

      {/* pull out Sidebar */}
      <div>
        <div
          className={`fixed top-0 left-0 right-0 h-full w-5/6 bg-white shadow-lg z-50 text-white transform transition-transform duration-300 ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <nav className="p-4 bg-custom-600 shadow-xl">
            <ul>
              <li className="mb-4">
                <div className="block rounded">
                  <button className="playwrite-fr-trad text-lg text-white font-[900]">
                    Catapot
                  </button>
                </div>
              </li>

              <li className="mb-2 flex gap-3">
                <Link to={`${SITELINK.MY_PROFILE}/${user?.username}`}>
                  <span className="block w-[40px] h-[40px] bg-[#223f64] rounded-full border border-green-100">
                    <h3 className="text-white text-[1.4rem] uppercase font-[600] relative top-0.5 left-2.5">
                      {getFirstLetters(user?.first_name ?? user?.username)}
                    </h3>
                  </span>
                </Link>
                <div className="text-sm">
                  <span className="gap-1 flex">
                    <span>{user?.username}</span>
                    <span>
                      {user?.is_verified && <img src={Verify} alt="" className="h-[12px]" />}
                    </span>
                  </span>
                  <span className="block text-[10px] text-gray-300">{user?.email}</span>
                </div>
              </li>
            </ul>
          </nav>

          <nav className="p-4 text-gray-700 font-medium text-sm overfloy-y-scroll">
            <ul>
              <li className="mb-2">
                <Link to={`${SITELINK.MY_PROFILE}/${user?.username}`} className="flex gap-4 p-3 hover:bg-gray-50">
                  <RiAccountPinCircleLine className="text-2xl" />
                  <span className="mt-1">Profile</span>
                </Link>
              </li>
              <li className="mb-2">
                <Link to={SITELINK.SUBSCRIPTIONS} className="flex gap-4 p-3 hover:bg-gray-50">
                  <RiGift2Line className="text-2xl" />
                  <span className="mt-1">Premium Services</span>
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to={SITELINK.MY_SUBSCRIPTION_HISTORY}
                  className="flex gap-4 p-3 hover:bg-gray-50"
                >
                  <RiSecurePaymentFill className="text-2xl" />
                  <span className="mt-1">Subscriptions</span>
                </Link>
              </li>
              <li className="mb-2">
                <Link to={SITELINK.BOOKMARKS} className="flex gap-4 p-3 hover:bg-gray-50">
                  <RiSignalTowerFill className="text-2xl" />
                  <span className="mt-1">Posts</span>
                </Link>
              </li>
              <li className="mb-2 hidden">
                <Link to={SITELINK.LIKED_POSTS} className="flex gap-4 p-3 hover:bg-gray-50">
                  <RiHeart3Line className="text-2xl" />
                  <span className="mt-1">Liked Post</span>
                </Link>
              </li>
              <li className="mb-2">
                <Link to={SITELINK.BOOKMARKS} className="flex gap-4 p-3 hover:bg-gray-50">
                  <RiBookmarkLine className="text-2xl" />
                  <span className="mt-1">Saved</span>
                </Link>
              </li>
              <li className="mb-2">
                <Link
                  to={SITELINK.PRIVACY_AND_SETTINGS}
                  className="flex gap-4 p-3 hover:bg-gray-50"
                >
                  <RiSettings5Line className="text-2xl" />
                  <span className="mt-1">Settings</span>
                </Link>
              </li>
              <li className="mb-2">
                <button
                  type="button"
                  onClick={() => {
                    localStorage.clear();
                    navigate(SITELINK.LOGIN);
                  }}
                  className="flex gap-4 p-3 hover:bg-gray-50"
                >
                  <RiLogoutCircleLine className="text-2xl" />
                  <span className="mt-1">Logout</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>

        <div
          onClick={toggleSidebar}
          className={`fixed top-0 left-0 right-0 h-full w-full bg-[#00000042] shadow-lg z-10 text-white transform transition-transform duration-300 ${
            isOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        ></div>
      </div>
    </>
  );
};

export default TopNav;
